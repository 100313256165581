

















































































































































































































import Component, { mixins } from 'vue-class-component';
import { currencyFormat, loadView, loadWidget } from '@/utils/helpers';
import { namespace } from 'vuex-class';
import { IOrganizations, IPaginate, IStore, ITransactions, IUser } from '@/types/types';
import timestamp from '@/filters/timestamp';
import mediumZoom from 'medium-zoom';
import { Watch } from 'vue-property-decorator';
import WidgetMixins from '@/mixins/WidgetMixins';
import DialogMixins from '@/mixins/DialogMixins';
import moment from 'moment';
import qs from 'qs';
const transactionsModule = namespace('transactions');
const organizationModule = namespace('organizations');
const authModule = namespace('auth');
@Component({
  name: 'Transactions',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    GetDetails: loadView('pages/products/dialogs/GetDetails'),
  },
  filters: {
    currencyFormat,
    timestamp,
  },
})
export default class Transactions extends mixins(WidgetMixins, DialogMixins) {
  @transactionsModule.Getter('getAllTransactions') transactions!: ITransactions[];
  @transactionsModule.Getter('getPagination') pagination!: IPaginate;
  @organizationModule.Getter('getAllOrganizations') organizations!: IOrganizations[];
  @authModule.Getter('getUserDetails') details!: IUser & IStore;
  @transactionsModule.Getter('getTransactionDetails') transactionDetails!: ITransactions;

  menu: boolean = false;
  options: any = {};
  searchTerm: string | null = null;
  date: string | null = null;
  statuses: Array<string> = ['ALL', 'SUCCESS', 'FAILED'];
  query: string = '';
  startDateMenu: boolean = false;
  endDateMenu: boolean = false;

  startDate: string | null = null;
  endDate: string | null = null;
  status: string | null = 'ALL';
  organizationId: string | null = null;

  headers: Array<{ text: string; value: string; align?: string }> = [
    {
      text: 'Date',
      value: 'createdAt',
    },
    {
      text: 'Ref.',
      value: 'reference',
    },
    {
      text: 'Acc/No.',
      value: 'accountNumber',
    },
    {
      text: 'Org.',
      value: 'meta',
    },
    {
      text: 'Amt.',
      value: 'totalAmount',
    },
    {
      text: 'Status',
      value: 'paymentStatus',
    },
    {
      text: 'Actions',
      value: 'actions',
    },
  ];

  @Watch('status')
  onStatusChange(value: string): void {
    this.query = `?page=${this.pagination.page}&size=${this.pagination.itemsPerPage}${
      value !== 'ALL' ? '&status=' + this.status : ''
    }${this.organizationId !== null ? '&organizationId=' + this.organizationId : ''}&startDate=${
      this.startDate
    }&endDate=${this.endDate}`;
    this.$store.dispatch('transactions/list', this.query);
  }

  @Watch('organizationId')
  onOrganizationIdChange(value: string | null): void {
    const orgName = this.organizations.find(({ _id }) => _id === value)?.name;
    const routeQuery = { ...this.$route.query, ...(value && { organizationId: value }) };
    if (!value) {
      delete routeQuery?.organizationId;
      delete routeQuery?.orgName;
    }
    this.$router
      .replace({
        name: 'cu.transactions',
        query: routeQuery,
      })
      .catch(() => {});
    this.query = `?page=${this.pagination.page}&size=${this.pagination.itemsPerPage}${
      this.status !== 'ALL' ? '&status=' + this.status : ''
    }${value ? '&organizationId=' + value : ''}&startDate=${this.startDate}&endDate=${this.endDate}`;
    this.$store.dispatch('transactions/list', this.query);
  }

  @Watch('options', {
    deep: true,
  })
  onOptionsChange(paginate: IPaginate): void {
    const orgName = this.organizations.find(({ _id }) => _id === this.$route.query.organizationId)?.name;
    this.$router
      .replace({
        name: 'cu.transactions',
        query: {
          ...this.$route.query,
          page: paginate.page.toString(),
          size: paginate.itemsPerPage.toString(),
          ...(orgName && { orgName }),
        },
      })
      .catch(() => {});
    this.query = `?page=${paginate.page}&size=${paginate.itemsPerPage}${
      this.status !== 'ALL' ? '&status=' + this.status : ''
    }${this.organizationId !== null ? '&organizationId=' + this.organizationId : ''}&startDate=${
      this.startDate
    }&endDate=${this.endDate}`;
    this.$store.dispatch('transactions/list', this.query);
  }

  @Watch('startDate')
  onStartDateChange(date: string): void {
    this.query = `?${qs.stringify({ ...qs.parse(this.query, { ignoreQueryPrefix: true }), startDate: date })}`;
    this.$store.dispatch('transactions/list', this.query);
  }

  @Watch('endDate')
  onEndDateChange(date: string): void {
    this.query = `?${qs.stringify({ ...qs.parse(this.query, { ignoreQueryPrefix: true }), endDate: date })}`;
    this.$store.dispatch('transactions/list', this.query);
  }

  downloadFile(ext: string): void {
    const query = this.query + `&download=true&ext=${ext}`;
    this.$store.dispatch('transactions/list', query);
  }

  created(): void {
    this.$store.dispatch('auth/me');
    this.organizationId = (this.$route.query?.organizationId as string) ?? null;
    this.startDate = moment()
      .subtract(12, 'month')
      .format('YYYY-MM-DD');
    this.endDate = moment().format('YYYY-MM-DD');

    if (this.$route.query.page && this.$route.query.size && this.organizationId && this.$route.query.ext) {
      this.query = `?${qs.stringify({ ...this.$route.query, startDate: this.startDate, endDate: this.startDate })}`;
      this.$store.dispatch('transactions/list', this.query);
    } else {
      this.query = `?page=1&size=15&startDate=${this.startDate}&endDate=${this.endDate}`;
      this.$store.dispatch('transactions/list', this.query);
    }
    this.$store.dispatch('organizations/list', true);
  }
  mounted(): void {
    mediumZoom('.productPreview');
  }
}
